.instagram-button {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1.6rem !important;
  border-style: solid #000;
  padding: 10px;
  margin-bottom: 10px !important;
}

.instagram-button:hover {
  background-color: #CE1446;
  color: #ffffff;
}